import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Form from '../../components/Form'

import service2 from '../../images/services-image2.jpg'



const PageOne = () => {
    return (
        <Layout>
        <SEO title='Columbia Maryland CPA' />    
            <section>
                <div className='service-page-header'>
                    <div className='service-page-header-left'>
                        <h1 style={{color: `#B62020`}}>ACCOUNTING</h1>
                        <p>
                        Accounting is the process of recording financial transactions pertaining to a 
                        business. The accounting process includes summarizing, analyzing and reporting these 
                        transactions to oversight agencies, regulators and tax collection entities. The 
                        financial statements used in accounting are a concise summary of financial transactions 
                        over an accounting period, summarizing a company's operations, financial position 
                        and cash flows. Tax law is complex, and the IRS puts a lot of restrictions on how small 
                        businesses file and what sort of documentation they need for filing. This can be a lot 
                        for a business with only a few employees to handle. Below are 3 reasons to hire a certified tax professional.
                        <br/>
                        <br/>
                        <h3 style={{color: `#B62020`}}>TAX EXPERTISE SAVES YOU MONEY</h3>
                        The number one reason small businesses hire accountants or outsource their bookkeeping for tax season is because 
                        accountants can save you money. The tax system is complex, and it takes time and experience for someone to learn how it works.
                        When you hire a tax professional, you’re getting someone who has helped out plenty of businesses like yours with their 
                        taxes. They know how it’s done and they know how to minimize your liability and maximize your possible refund. 
                        <br/>
                        <br/>
                        <h3 style={{color: `#B62020`}}>YOU'RE PAYING FOR EFFICIENCY</h3>
                        Think about it. If you decide to do it yourself, or you give the task of handling your taxes to another employee at your small 
                        business, how many hours are going to be spent just learning how things are done? According to recent surveys, it takes small 
                        business owners 8.8 hours to prepare and file their taxes. Conversely, it takes less than 2 for them to get all the paperwork 
                        they needed in order and hand it off to an accountant. 
                        <br/>
                        <br/>
                        <h3 style={{color: `#B62020`}}>HEALTHY BUSINESSES NEED GOOD BOOKKEEPING</h3>
                        Many small businesses don’t worry too much about record-keeping until tax season comes around. You may keep and store all of your 
                        receipts and old transaction records, but there’s always more important work to do that keeps you away from the mundane tasks of 
                        collecting records in one place, reconciling accounts and everything else involved in bookkeeping. 
                        It’s hard to find time to prioritize bookkeeping for a small business when everyone has their own work to do and there are not any 
                        employees who are solely concerned with accounting. Now that some bookkeeping has to happen for tax season already, you have an opportunity 
                        to build for future success in your company by investing in an accountant that can help you grow your business. 
                        <br/>
                        <br/>
                        I am happy to help with all of your accounting needs. Whether it's your personal taxes, or 
                        keeping your business books in order, feel free to contact me and get your accounting needs professionally
                        met.
                        </p>
                    </div>
                    <div className='service-page-header-right'>
                        <div className='service-page-header-photo-container'>
                            <img src={service2} alt='fix this alt'/>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='contact-section'>
                    <div className='contact-section-top'>
                    <p style={{margin: `0`}}>CONTACT</p>
                    </div>
                    <div className='contact-section-bottom'>
                    <div className='contact-section-bottom__left'>
                        <p>
                        Contact me by phone at <span style={{color: `#B62020`}}>(410)271-8259</span> 
                        <br/>
                        <br/>
                        or
                        <br/>
                        <br/>
                        Fill out the contact form and I'll reach out to you as soon as possible.
                        </p>
                    </div>
                    <div className='contact-section-bottom__right'>
                        <Form />
                    </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PageOne